<div class="container" fxFlex="100" fxLayoutAlign="center center">
    <div class="videos" fxFlex="100"  fxLayoutAlign="center center">
        <div class="videoBuilder"  fxHide.xs fxFlex="100">
            <video src="https://landingpagespolimentes.s3.us-east-2.amazonaws.com/construccion/construccion__web.mp4" autoplay="true" autoplay
                loop="loop" muted playsinline data-object-fit="cover" preload="auto" onloadedmetadata="this.muted = true"
                fxFlex="100">
            </video>
        </div>
        <div class="videoBuilder" fxHide.gt-xs   fxLayoutAlign="center center" fxFlex="100">
            <video src="https://landingpagespolimentes.s3.us-east-2.amazonaws.com/construccion/construccion__movil.mp4" autoplay="true" autoplay
                loop="loop" muted playsinline data-object-fit="cover" preload="auto" onloadedmetadata="this.muted = true"
                fxFlex="100">
            </video>
        </div>
        <div class="container-img" fxLayoutAlign="center center">
            <a fxLayoutAlign="center center" href="https://polimentes.com/home"><img src="/assets/img/PolimentesLogo.png" alt="" class="img" fxFlex.xl="95" fxFlex.lg="45" fxFlex.md="40"  fxFlex.sm="30" fxFlex.xs="45"></a>     
        </div>
    </div>
</div>