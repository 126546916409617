import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';

const routes: Routes = [
  {
    path: 'site-construction',
    component: HomeComponent
  },
//   {
//     path: 'alta-colaborador',
//     component: DISPERSIONS_MOVEMENTS_A_AltaComponent
//   },
//   {
//     path: 'administrar-colaborador',
//     component: DISPERSIONS_MOVEMENTS_A_AdministrationComponent
//   },
//   {
//     path: 'baja-colaborador',
//     component: DISPERSIONS_MOVEMENTS_A_UnsuscribeComponent
//   },

   {path: '**',pathMatch: 'full', redirectTo: 'site-construction'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
